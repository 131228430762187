#tab-landing {
  min-height: 100%;
  overflow: hidden;

  p {
    padding-bottom: 10px;
  }

  .line-1 {
    margin-bottom: 20px;
  }

  .install-wrapper {
    position: absolute;
    top: 17px;
    right: 60px;
    height: 0;
    overflow: hidden;

    @media only screen and (width >= 1660px) {
      &.active {
        animation: slide 1s ease 0.5s forwards;
      }
    }
  }

  @keyframes slide {
    from {height: 0;}
    to {height: 150px;}
  }

  .install {
    max-width: 400px;
    padding: 10px;
    background: rgb(211 211 211 / 25%);

    .description {
      text-align: center;
    }

    .default-btn {
      margin: auto;

      button {
        max-width: 200px;
      }
    }
  }

  .melody-editor-button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;

    button {
      max-width: 200px;
    }
  }

  .firmware-logo-bar {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      width: 50%;
    }
  }

  a.discord-link {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 20px;

    img.discord {
      height: 50px;
    }
  }

  .content_wrapper {
    padding: 0;
    overflow-y: auto;
  }

  .content_top {
    min-height: 140px;
    background: var(--color-primary);
    padding: 20px;
    display: flex;

    @media only screen and (width <= 600px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .content_mid {
    background-color: #eaeaea;
    overflow: hidden;
    display: flex;
    padding-bottom: 45px;
    padding-top: 20px;

    @media only screen and (width <= 991px) {
      & {
        flex-direction: column;
      }
    }

    .summary-section {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }

      h3 {
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid silver;
      }

      section {
        display: flex;

        p {
          padding-bottom: 10px;
        }

        p:last-of-type {
          padding-bottom: 0;
        }

        img {
          width: 128px;
          height: 128px;
          margin-right: 15px;
        }
      }
    }

    a,
    a:hover {
      text-decoration: underline;
    }

    & > div {
      flex: 1
    }

    .column {
      .wrap {
        padding: 15px;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    h2 {
        margin-bottom: 5px;
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 2px;
    }
  }

  .logowrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    color: white;
    font-size: 14px;
    font-family: 'open_sanslight', Arial, sans-serif;

    a {
      color: white;
      text-decoration: underline;
    }

    img {
      width: 420px;
      margin: 5px;
    }

    span {
      font-size: 22px;
      font-family: 'open_sanslight', Arial, sans-serif;
    }
  }

  .text1, .text2, .text3 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: normal;
    font-family: 'open_sansregular', Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
  }

  .text1 .wrap {
      margin-left: 0;
  }

  .text3 ul,
  .text2 ul {
    margin-top: 10px;

    li {
      line-height: 24px;
      margin-left: 20px;
      list-style: disc;
    }
  }
}
