#melody-editor {
  .overlay {
    .overlay__wrapper {
      max-width: 960px;
      width: 80%;

      @media screen and (width <= 600px) {
        width: 90%;
      }
    }
  }

  .line-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    select {
      margin-left: 10px;
      height: 20px;
      border: 1px solid silver;
      border-radius: 3px;
    }

    .melody-selection-wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 6px;

      .info-wrapper-wrapper {
        display: none;
      }

      .default-btn {
        margin-top: -2px;
        margin-left: 10px;

        button {
          width: 100px;
        }
      }
    }

    @media screen and (width <= 768px) {
      flex-direction: column;

      select {
        margin-left: 0;
      }

      .melody-selection-wrapper {
        .default-btn button {
          width: inherit;
        }
      }
    }
  }

  .save-melody-wrapper {
    display: flex;
    flex-direction: row;

    input {
      border: 1px solid silver;
      padding-left: 5px;
      padding-right: 5px;
      line-height: 23px;
    }

    .default-btn {
      margin-left: 10px;

      button {
        width: 100px;

        @media screen and (width <= 768px) {
          width: inherit;
        }
      }
    }
  }

  .sync-wrapper {
    .checkbox {
      display: flex;
    }
  }

  .melody-editor-escs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    &.all {
      .esc-melody-wrapper {
        width: 100%;
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      width: 100px;
      margin-left: 15px;
    }
  }

  @media screen and (width <= 600px) {
    .melody-editor-escs.single {
      .esc-melody {
        margin-top: 5px;
        margin-bottom: 5px;

        header {
          flex-direction: column;
          border: 0;
          padding-bottom: 0;
          margin-bottom: 4px;

          .default-btn {
            margin-top: 2px;
            margin-left: -2px;
            margin-right: -2px;

            button {
              margin-left: 2px;
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
}
