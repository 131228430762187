.missing-esc.gui-box {
  p {
    padding-bottom: 10px;
    font-weight: normal;
  }

  ul {
    margin-left: 15px;

    li {
      list-style-type: disc;
      font-weight: normal;
      padding-bottom: 5px;
    }
  }
}
