.not-in-sync::before {
  content: '*';
  color: red;
  font-weight: bold;
}

fieldset:not(:first-child) {
  margin-top: 10px;

  legend {
    width: 100%;
    border-bottom: 1px solid var(--color-tertiary);
    padding-top: 0px;
    padding-bottom: 7px;
  }
}
