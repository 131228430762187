.generic-button {
  button {
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    border-radius: 3px;
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: #fff;
    font-family: 'open_sansbold', Arial, sans-serif;
    font-size: 12px;
    text-shadow: 0 1px rgb(0 0 0 / 25%);
    display: block;
    transition: all ease 0.2s;
    padding: 0;
    padding-left: 9px;
    padding-right: 9px;
    line-height: 28px;
  }

  button:hover {
    background-color: var(--color-secondary);
    transition: all ease 0.2s;
  }

  button:active {
    background-color: var(--color-primary);
    transition: all ease 0.0s;
    box-shadow: inset 0 1px 5px rgb(0 0 0 / 35%);
  }

  button[disabled] {
    cursor: default;
    color: #fff;
    background-color: #AFAFAF;
    border: 1px solid #AFAFAF;
    pointer-events: none;
    text-shadow: none;
    opacity: 0.5;
  }
}
