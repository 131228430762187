.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay__wrapper {
    padding: 10px;
    background: white;
    max-width: 500px;
    width: 80%;
    position: relative;

    .overlay__close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      font-weight: bold;
    }

    h3 {
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid silver;
    }
  }
}
