.esc {
  progress {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-tertiary);
    border-radius: 5px;
    z-index: 1;
  }

  progress::-webkit-progress-bar {
    background: #fff;
    border-radius: 5px;
  }

  progress::-webkit-progress-value {
    background: var(--color-tertiary);
  }

  .progress-text {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    line-height: 25px;
    font-weight: bold;
    color: white;
  }

  .firmware-dump {
    margin-top: 7px;
  }
}
