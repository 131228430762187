#content {
  margin-top: 0;
  padding: 0;
  padding-top: 137px;

  /* padding-bottom: 50px; */
  background-color: lightgray;
  overflow-x: hidden;
  overflow-y: auto;
  border: 0 solid #848484;
  transform: rotateX(0deg); /* DO NOT REMOVE! this fixes the UI freezing bug on MAC OS X */
  transition: all 0.3s;

  .note {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;

    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    @media screen and (width <= 768px) {
      margin-bottom: 18px;
    }

    &.warning {
      margin-bottom: 10px;
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }
  }

  .note.alert {
    background-color: #bc544b;
    border: 1px solid #f00;
    color: white;

    p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (width <= 600px) {
    padding-top: 170px
  }

  .tab {
    position: relative;
    margin-bottom: 5px;

    .spacer-box {
      padding: 10px;
      margin-bottom: 3px;
      padding-bottom: 10px;
      width: calc(100% - 20px);
    }

    .spacer-box-title {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 4px;
      margin-bottom: 0;
      color: white;
    }

    .gui-box {
      margin-bottom: 10px;
      font-weight: bold;
      font-family: 'open_sanssemibold', Arial, sans-serif;
      background-color: #FFF;
      border: 1px solid var(--color-tertiary);
      border-radius: 4px;
      width: calc(100% - 2px);

      &.grey {
        background-color: #f9f9f9
      }

      .gui-box-titlebar {
        margin-bottom: 0;
        background-color: var(--color-tertiary);
        border-radius: 3px 3px 0 0;
        font-size: 13px;
        width: 100%;
        height: 27px;
        padding-bottom: 0;
        font-family: 'open_sanssemibold', Arial, sans-serif;
      }

      span {
        font-weight: normal;
        font-family: 'open_sansregular', Arial, sans-serif;
        line-height: 19px;
        color: #4F4F4F;
        font-size: 11px;
      }
    }

    .alert {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      position: relative;
      padding: 0.75rem 1.25rem;
      margin-bottom: 10px;
      border: 1px solid transparent;
      border-radius: 0.25rem;

      p {
        margin-bottom: 10px;
        font-weight: normal;

        ul {
          font-weight: normal;
          li {
            list-style-type: disc;
            margin-left: 20px;
          }
        }
      }

      span,
      a {
        color: #721c24;
        font-size: inherit;
      }
    }

    .checkbox,
    .number,
    .number-text,
    .select {
      margin-top: 7px;
      margin-bottom: 7px;

      label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .input-wrapper {
        flex: 0 0 218px;

        input:not([type="checkbox"]),
        select {
          width: 100%;
          height: 20px;
          border: 1px solid silver;
          border-radius: 3px;
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .checkbox {
      display: flex;
      flex-direction: row;

      label {
        flex: 1;
        flex: 0 0 218px;
      }
    }

    .number {
      margin-top: 20px;

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
