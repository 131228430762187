:root {
  /*
  --color-primary: #125684;
  --color-secondary: #2a84c4;
  --color-tertiary: #252177;
  */

  --color-primary: #6A8347;
  --color-secondary: #71B238;
  --color-tertiary: #6A8347;
}
